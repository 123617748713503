import {Link} from 'gatsby'
import React, {useState, useEffect} from 'react'
import Icon from './icon'

const Header = ({siteTitle}) => {
  let headerClass = 'bg-white z-50 fixed top-0 px-4 pb-4 w-full '
  const [isExpanded, toggleExpansion] = useState(false)
  const [scrollDirection, setScrollDirection] = useState(null)
  const [prevOffset, setPrevOffset] = useState(0)

  const toggleScrollDirection = () => {
    let scrollY = window.scrollY
    if (scrollY === 0) {
      setScrollDirection(null)
    }
    if (scrollY > prevOffset) {
      setScrollDirection('disappear')
    } else if (scrollY < prevOffset) {
      setScrollDirection('back')
    }
    setPrevOffset(scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleScrollDirection)

    return () => {
      window.removeEventListener('scroll', toggleScrollDirection)
    }
  })

  return (
    <nav id='header' className={headerClass + scrollDirection}>
      <hr className='my-4 border-b-1 border-black' />
      <div className='flex flex-wrap items-center justify-between mt-0 font-unica'>
        <Link className='text-xl lg:text-2xl tracking-tight' id='siteTitle' to='/'>
          {siteTitle}
        </Link>
        <button id='menu' onClick={() => toggleExpansion(!isExpanded)} className='menu lg:hidden flex justify-end flex-1 appearance-none'>
          <Icon symbol='hamburger' />
        </button>

        <div className={`${isExpanded ? `block` : `hidden`} lg:flex w-full lg:w-auto block`}>
          <hr className='my-4 border-b-1 border-black' />
          <ul className='flex justify flex-1'>
            <li className=''>
              <Link className='mr-4 text-xl lg:text-2xl tracking-tight' activeClassName='underline' to='/about'>Profile</Link>
            </li>
            <li className=''>
              <Link className='text-xl lg:text-2xl tracking-tight' activeClassName='underline' to='/contact'>Contact</Link>
            </li>
          </ul>
          <hr className='lg:hidden my-4 border-b-1 border-black' />
          <div className='lg:hidden'>
            <ul>
              <li className='inline mr-4'><a className='underline' href='http://instagram.com/_erichu' target='_blank'>@_erichu</a></li>
              <li className='inline'><a className='underline' href='mailto:erichu.info'>mail@erichu.info</a></li>
              <hr className='lg:hidden my-4 border-b-1 border-black' />
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
