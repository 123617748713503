import React from 'react'

const Footer = ({siteTitle}) => (
  <footer className='clean-both w-full absolute bottom-0 h-8'>
    <div>
      <div className='font-unica text-xs px-4'>
        <hr className='border-b-1 border-black py-2' />
    © {new Date().getFullYear()}, Nothing in Common LLC d/b/a {siteTitle}
      </div>
    </div>
  </footer>
)

export default Footer
