import React from 'react'
import Header from './header'
import Footer from './footer'
import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({children, siteTitle}) => (
  <>
    <div className='relative' style={{minHeight: '87vh'}}>
      <Header siteTitle={siteTitle} />
      <main className={styles.content}>{children}</main>
      <Footer siteTitle={siteTitle} />
    </div>
  </>
)

export default Layout
